import { SafeA } from "../../components/Safe/SafeStyled";

export const FirstLoginResourcesBS: FirstLoginResourcesInterface = {
  title: "First Login Page",
  nextButton: "Naprijed",
  backButton: "Nazad",
  signatureTitle: "Potpis",
  errorMissingInput: "Molimo popunite sva polja",
  errors: {
    registerPic: "The picture you are uploading is too large.",
  },
  profilePicture: {
    label: "Profile Picture",
    name: "profilePicture",
    error: "Please select your profile picture",
    validation: "Wrong profile picture",
    description: () => (
      <>
        <h4> files here or click to upload.</h4>
        <span className="text-muted font-13">Only images, max 4MB</span>
      </>
    ),
  },
  successModal: {
    title: "Unos podataka",
    body: "Dodavanje podataka je uspješno završeno. Odaberite OK za nastavak.",
    buttonOk: "OK",
  },
  errorPasswordMatch: "Sifre se ne podudaraju.",
  password: {
    label: "Sifra",
    placeholder: "Molimo unesite sifru",
  },
  passwordRepeat: {
    label: "Ponovite sifru",
    placeholder: "Molimo ponovite sifru",
  },
  termsAgreementInput: {
    name: "termsConditions",
    error: "Molimo prihvatite uslove koristenja",
    label:
      "Prihvatam uslove koristenja, politiku privatnosti i upoznat sam sa ostalim pravilima koje su dostupne u aplikaciji.",
  },
  termsAgreement: (uslovipath, politika, ostalapravila) => (
    <>
      <SafeA
        href={`${process.env.PUBLIC_URL}/pdf/` + uslovipath}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="mdi me-2"></i>
        Uslovi koristenja
      </SafeA>
      <SafeA
        href={`${process.env.PUBLIC_URL}/pdf/` + politika}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="mdi me-2"></i>
        Politika privatnosti
      </SafeA>
      <SafeA
        href={`${process.env.PUBLIC_URL}/pdf/` + ostalapravila}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="mdi me-2"></i>
        Ostala pravila
      </SafeA>
    </>
  ),
};

interface IErrors {
  registerPic: string;
}

export interface FirstLoginResourcesInterface {
  title: string;
  nextButton: string;
  backButton: string;
  signatureTitle: string;
  errorMissingInput: string;
  profilePicture: {
    label: string;
    name: string;
    error: string;
    validation: string;
    description: () => JSX.Element;
  };
  errorPasswordMatch: string;
  password: {
    label: string;
    placeholder: string;
  };
  passwordRepeat: {
    label: string;
    placeholder: string;
  };
  successModal: {
    title: string;
    body: string;
    buttonOk: string;
  };
  termsAgreement: (
    uslovipath: string,
    politika: string,
    ostalapravila: string
  ) => JSX.Element;
  termsAgreementInput: {
    label: string;
    name: string;
    error: string;
  };
  errors: IErrors;
}
