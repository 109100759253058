import { Form, FormLabel, Nav } from "react-bootstrap";
import { darken, lighten } from "polished";

import { Link } from "react-router-dom";
import styled from "styled-components";

export const SafeParagraph = styled.footer`
  color: ${(props) => props.theme.mainColor} !important;
`;

export const SafeParagraphSecond = styled.footer`
  color: ${(props) => props.theme.secondColor} !important;
`;

export const SafeLi = styled.footer`
  color: ${(props) => props.theme.mainColor} !important;
`;

export const ButtonMain = styled.button`
  background-color: ${(props) => props.theme.secondColor};
  color: white;
  border: none;
  padding: 0.5rem;
  &:hover {
    background-color: ${(props) => darken(0.2, props.theme.secondColor)};
    color: ${(props) => props.theme.mainColor};
  }
`;

export const ButtonSecond = styled.button`
  color: ${(props) => props.theme.secondColor};
  background-color: ${(props) => props.theme.mainColor};
  border: none;
  &:hover {
    background-color: ${(props) => lighten(0.2, props.theme.mainColor)};
    color: ${(props) => props.theme.secondColor};
  }
`;

export const SafeLink = styled(Link)`
  color: ${(props) => props.theme.mainColor} !important;
  &:hover {
    color: ${(props) => lighten(0.2, props.theme.mainColor)} !important;
  }
`;

export const SafeLinkButton = styled(Link)`
  background-color: ${(props) => props.theme.secondColor} !important;
  border: none !important;
  &:hover {
    background-color: ${(props) =>
      lighten(0.2, props.theme.secondColor)} !important;
  }
`;

export const SafeA = styled.a`
  color: ${(props) => props.theme.secondColor} !important;
  &:hover {
    color: ${(props) => lighten(0.2, props.theme.secondColor)} !important;
  }
`;

export const SafeSpan = styled.span`
  color: ${(props) => props.theme.mainColor} !important;
  &:hover {
    color: ${(props) => lighten(0.2, props.theme.mainColor)} !important;
  }
`;

export const SafeIcon = styled.i`
  color: ${(props) => props.theme.secondColor};
`;

export const SafeH1 = styled.h1`
  color: ${(props) => props.theme.mainColor};
`;

export const SafeH4Second = styled.h4`
  color: ${(props) => props.theme.secondColor};
`;

export const SafeH3Second = styled.h3`
  color: ${(props) => props.theme.secondColor};
`;

export const SafeH5WithBackground = styled.h5`
  color: ${(props) => props.theme.secondColor};
  background-color: ${(props) => props.theme.mainColor};
`;

export const SafeH5WithBackgroundSecond = styled.h5`
  color: ${(props) => props.theme.mainColor};
  background-color: ${(props) => props.theme.secondColor};
`;

export const SafeSelect = styled(Form.Check)`
  .form-check-input:checked {
    background-color: ${(props) => props.theme.secondColor};
    border-color: ${(props) => props.theme.secondColor};
  }

  .form-check-label {
    color: ${(props) => props.theme.mainColor};
  }
`;

export const SafeNavLink = styled(Nav.Link)`
  &:hover {
    background-color: ${({ theme }) => theme.secondColor};
    color: ${({ theme }) => theme.mainColor};
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.secondColor} !important;
    color: ${theme.mainColor} !important;
  `}
`;

export const SafeModal = styled.div`
  background-color: ${({ theme }) => theme.mainColor} !important;
`;

export const SafeTooltipIcon = styled.i`
  color: ${(props) => props.theme.secondColor} !important;
  &:hover {
    cursor: pointer !important;
  }
`;

export const SafeFormLabel = styled(FormLabel)`
  color: ${(props) => props.theme.secondColor} !important;
`;

// Define the Button component using styled-components
export const SafeFaqButton = styled.button`
  color: ${(props) => props.theme.mainColor};
  background-color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.secondColor};
    border-color: ${(props) => props.theme.secondColor};
  }
`;

export const SafeSubparagraphSecond = styled.p`
  color: ${(props) => props.theme.secondColor} !important;
  opacity: 0.5;
`;

export const SafeImg = styled.img`
  max-width: 500px; /* Change this value as needed */
  max-height: 500px; /* Change this value as needed */
  width: 100%;
  height: auto;
`;

export const SafeSubImg = styled.img`
  max-width: 100px; /* Change this value as needed */
  max-height: 100px; /* Change this value as needed */
  width: auto;
  height: auto;
`;

export const SafeBtnMain = styled.button`
  background-color: ${(props) => props.theme.mainColor} !important;
`;

export const SafeBtnSecond = styled.button`
  background-color: ${(props) => props.theme.secondColor} !important;
`;
