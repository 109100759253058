export interface AboutUsInterface {
  title: string;
  document: string;
  pdf: {
    cookies: string;
    endUser: string;
    izjava: string;
    politika: string;
    saglasnost: string;
    uslovi: string;
  };
}

export const AboutUsBS: AboutUsInterface = {
  title: "O nama",
  document: "Dokumenti",
  pdf: {
    cookies: "Kolačići",
    endUser: "Uslovi koristenja",
    izjava: "Politiku privatnosti",
    politika: "Politika privatnosti",
    saglasnost: "Saglasnost za obradu podataka",
    uslovi: "Uvjeti korištenja",
  },
};
